import Switcher from "@/components/switcher";
import {
    FacebookIcon,
    InstagramIcon,
    TwitterIcon,
    LinkedinIcon,
    HomeIcon,
    UserIcon,
    KeyIcon
} from "vue-feather-icons";

import Vue from "vue";
import axios from "axios";
import { EventBus } from "@/event-bus";

import warnningComponent from "../../components/warningAlert.vue";

import infoService from "@/services/infoService";

/**
 * Auth-login component
 */
export default {
    data() {
        return {
            valid: true,
            email: null,
            password: null,

            textWarning: "",
            typeWarning: "",
            showWarnnig: false,
            organizations: [],
            showPicker: false,
            selectedUser: null,
            overlay: false,
        };
    },
    components: {
        Switcher,
        FacebookIcon,
        InstagramIcon,
        TwitterIcon,
        LinkedinIcon,
        HomeIcon,
        UserIcon,
        KeyIcon,
        warnningComponent
    },
    mounted() {
        let token = this.$cookies.get("user_token") || null;
        if (token != null) {
            this.$router.replace({
                path: "/home"
            }).catch(err => {
                // do nothing
                this.overlay = false
            })
        }
    },
    methods: {
        warnnig(text, type) {
            (this.textWarning = text),
                (this.typeWarning = type),
                (this.showWarnnig = true);
        },
        onSelectUser() {
            if (this.selectedUser != null) {
                this.signin(this.selectedUser)
            }
        },
        onCancel() {
            this.selectedUser = null
            this.overlay = false
            this.showPicker = false
        },
        identifyCompany() {
            this.overlay = true
            axios
                .post(`/account/tula/login/company/admin`, {
                    username: this.email
                })
                .then(resp => {
                    if (resp.status == 200) {
                        if (resp.data.status == true && resp.data.result.length > 0) {
                            if (resp.data.result.length > 1) {
                                // show organization picker
                                // companyData = resp.data.result[1]
                                this.organizations = resp.data.result
                                this.showPicker = true
                            } else {
                                let companyData = resp.data.result[0] || null;
                                this.signin(companyData);
                            }

                            //check for testing
                            // if (this.email == 'support@nerdof.com') {
                            //     let index = resp.data.result.findIndex((element) => element.companies[0]._id == '5f3b5147a48c4d52a612d625')
                            //     companyData = resp.data.result[index];
                            // }


                        } else {
                            this.warnnig("ไม่พบผู้ใช้", "warning");
                            this.overlay = false
                        }
                    }
                })
                .catch(err => {
                    this.overlay = false
                    console.error(err);
                })
        },
        signin(companyData) {
            this.overlay = true
            const params = {
                id: companyData._id,
                password: this.password,
                platform: "Web app"
                // device: this.$platformDetect.ua,
                // osversion: `${this.$platformDetect.os}`,
                // browser: `${this.$browserDetect.meta.name} ${this.$browserDetect.meta.version}`
            };
            axios
                .post(`/account/tula/login/admin`, params)
                .then(resp => {
                    if (resp.status == 200 && resp.data.status == true) {
                        Vue.$cookies.set("user_token", resp.data.result.token);
                        EventBus.$emit("signIn");
                        this.getInfo();
                    } else {
                        this.overlay = false
                        this.warnnig("รหัสผ่านไม่ถูกต้อง", "warning");
                    }
                })
                .catch(err => {
                    this.overlay = false
                });
        },
        getInfo() {
            infoService
                .getInfo()
                .then((resp) => {
                    if (resp.data.status == true && resp.data.result != null) {
                        Vue.prototype.$userInfo = resp.data.result

                        if (this.$route.query.redirect != null) {
                            this.$router.replace(this.$route.query.redirect);
                        } else {
                            this.$router.replace({
                                path: "/"
                            });
                        }
                    } else {
                        throw "Can not get information user";
                    }
                })
                .catch((err) => {
                    this.overlay = false
                });
        },
    }
};